<template>
	<v-container fluid class="text-justify" style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('policies.cookies') }}</span>
		</div>

		<v-divider class="my-2" />
		<v-btn text :to="{ name: 'Policies' }">
			{{ $t('policies.goBack') }}
		</v-btn>
		<div class="my-10">
			<p>
				OverSOS proporciona una gran transparencia con respecto a cómo usamos tus datos, cómo recopilamos tus datos y con quién compartimos tus datos.
				Con ese fin, proporcionamos esta página, que detalla
				<a href="#OverSOS-Subprocessors">nuestros subprocesadores</a>, cómo usamos <a href="#Cookies-on-OverSOS">cookies</a>, y dónde y cómo
				realizamos <a href="#Tracking-on-OverSOS">seguimiento en OverSOS</a>.
			</p>
		</div>
		<div class="my-10">
			<h2 id="OverSOS-Subprocessors" class="terms-header">
				Subprocesadores de OverSOS
			</h2>
			<p>
				Cuando compartimos tu información con subprocesadores de terceros, como nuestros proveedores y proveedores de servicios, seguimos siendo
				responsables de ello. Trabajamos muy duro para mantener tu confianza cuando incorporamos nuevos proveedores, y requerimos que todos los
				proveedores celebren acuerdos de protección de datos con nosotros que restrinjan el procesamiento de la Información personal de los usuarios
				(como se define en la
				<router-link :to="{ name: 'PoliciesPrivacy' }">
					Declaracion de privacidad
				</router-link>
				).
			</p>
			<v-simple-table class="mx-auto my-3">
				<thead>
					<tr>
						<th class="text-center">
							Nombre del subprocesador
						</th>
						<th class="text-center">
							Descripción del procesamiento
						</th>
						<th class="text-center">
							Ubicación de procesamiento
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>PythonAnywhere</td>
						<td>Hosting Provider</td>
						<td>European Union</td>
					</tr>
					<tr>
						<td>OVH</td>
						<td>Hosting Provider</td>
						<td>European Union</td>
					</tr>
					<tr>
						<td>Google Apps</td>
						<td>Internal company infrastructure</td>
						<td>United States</td>
					</tr>
					<tr>
						<td>Google Analytics</td>
						<td>Website analytics and performance</td>
						<td>United States</td>
					</tr>
				</tbody>
			</v-simple-table>
			<p>
				Cuando incorporamos un nuevo proveedor u otro subprocesador que maneja la información personal de nuestros usuarios, o eliminamos un
				subprocesador, o cambiamos la forma en que usamos un subprocesador, actualizaremos esta página. Si tienes preguntas o inquietudes sobre un
				nuevo subprocesador, estaremos encantados de ayudarte. Por favor contáctanos vía
				<router-link :to="{ name: 'Contact' }">
					el formulario de contacto.
				</router-link>
			</p>
		</div>
		<div class="my-10">
			<h2 id="Cookies-on-OverSOS" class="terms-header">
				Cookies on OverSOS
			</h2>
			<p>
				OverSOS utiliza cookies para que las interacciones con nuestro servicio sean fáciles y significativas. Usamos cookies (y tecnologías
				similares, como HTML5 localStorage) para mantenerte conectado, recordar tus preferencias y proporcionar información para el desarrollo futuro
				de OverSOS.
			</p>
			<p>
				Una cookie es un pequeño fragmento de texto que nuestro servidor web almacena en tu computadora o dispositivo móvil, que tu navegador nos
				envía cuando regresas a nuestro sitio. Las cookies no te identifican necesariamente si simplemente estás visitando OverSOS; sin embargo, una
				cookie puede almacenar un identificador único para cada usuario registrado. Las cookies que establece OverSOS son esenciales para el
				funcionamiento del sitio web o se utilizan para el rendimiento o la funcionalidad. Al utilizar nuestro sitio web, aceptas que podemos colocar
				este tipo de cookies en tu computadora o dispositivo. Si deshabilitas la capacidad de tu navegador o dispositivo para aceptar cookies, no
				podrás iniciar sesión ni utilizar los servicios de OverSOS.
			</p>
			<p>
				OverSOS establece las siguientes cookies en nuestros usuarios por los siguientes motivos:
			</p>
			<v-simple-table class="mx-auto my-3">
				<thead>
					<tr>
						<th class="text-center">
							Nombre de la cookie
						</th>
						<th class="text-center">
							Motivo
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>session</td>
						<td>Esta cookie se utiliza para iniciar sesión.</td>
					</tr>
					<tr>
						<td>_ga</td>
						<td>Esta cookie es utilizada por Google Analytics.</td>
					</tr>
					<tr>
						<td>_gid</td>
						<td>Esta cookie es utilizada por Google Analytics.</td>
					</tr>
					<tr>
						<td>_gat_gtag_XX_XXXXXXXX_X</td>
						<td>Esta cookie es utilizada por Google Analytics.</td>
					</tr>
				</tbody>
			</v-simple-table>
			<p>
				Algunas páginas de nuestro sitio pueden establecer otras cookies de terceros. Por ejemplo, podemos incrustar contenido, como videos, de otro
				sitio que establece una cookie. Si bien tratamos de minimizar estas cookies de terceros, no siempre podemos controlar qué cookies establece
				este contenido de terceros.
			</p>
			<h2 id="Tracking-on-OverSOS" class="terms-header">
				Seguimiento en OverSOS
			</h2>
			<p>
				"<a href="https://www.eff.org/issues/do-not-track">No rastrear</a>" es una preferencia de privacidad que puedes establecer en tu navegador si
				no deseas que los servicios en línea, específicamente las redes publicitarias, recopilen y compartan ciertos tipos de información sobre tu
				actividad en línea de servicios de seguimiento de terceros. OverSOS no responde actualmente de manera diferente a la configuración "No
				rastrear" de un navegador individual. Si deseas configurar tu navegador para que indique que no deseas que te rastreen, consulta la
				documentación de tu navegador para saber cómo habilitar esa señal. También hay buenas aplicaciones que bloquean el rastreo en línea.
			</p>
			<p>
				No rastreamos tu actividad de navegación en línea en otros servicios en línea a lo largo del tiempo y no hospedamos publicidad de terceros en
				OverSOS que pueda rastrear tu actividad en nuestro sitio. Tenemos acuerdos con ciertos proveedores, como los proveedores de análisis, que nos
				ayudan a rastrear los movimientos de los visitantes en ciertas páginas de nuestro sitio. Solo nuestros proveedores, que recopilan datos en
				nuestro nombre, pueden recopilar datos en nuestras páginas, y hemos firmado acuerdos de protección de datos con todos los proveedores que
				recopilan estos datos en nuestro nombre. Usamos los datos que recibimos de estos proveedores para comprender mejor los intereses de nuestros
				visitantes, comprender el rendimiento de nuestro sitio web y mejorar nuestro contenido. Cualquier proveedor de análisis se incluirá en nuestra
				Lista de subprocesadores, y es posible que veas una lista de cada página donde recopilamos este tipo de datos a continuación.
			</p>
			<h4 class="terms-subheader">Google Analytics</h4>
			<p>
				Usamos Google Analytics como un servicio de análisis de terceros. Usamos Google Analytics para recopilar información sobre cómo funciona
				nuestro sitio web y cómo nuestros usuarios, en general, navegan y utilizan OverSOS. Esto nos ayuda a evaluar el uso que hacen nuestros
				usuarios de OverSOS; compilar informes estadísticos de actividad; y mejorar nuestro contenido y el rendimiento del sitio web. Google
				proporciona más información sobre sus propias prácticas de privacidad y
				<a href="https://tools.google.com/dlpage/gaoptout">ofrece un complemento de navegador para inhabilitar el seguimiento de Google Analytics</a>.
			</p>
			<h4 class="terms-subheader">Páginas en OverSOS donde se pueden habilitar las analíticas</h4>
			<p>
				Cualquier página de nuestro sitio puede tener habilitados análisis u otro código de seguimiento. Si deseas evitar que recopilemos información
				sobre tu actividad de navegación en OverSOS, puedes utilizar un bloqueador de seguimiento o excluirte del seguimiento de Google Analytics.
			</p>
		</div>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: `${this.$t('policies.title')} - ${this.$t('policies.cookies')}`
		}
	}
}
</script>

<style scoped>
.terms-header {
	color: firebrick;
	border-bottom: 2px solid grey;
}
.terms-subheader {
	color: indianred;
}
</style>
